export default {
  multichoice: [
    {
      id: 'sex',
      question: { sv: 'Är du kvinna eller man?', us: 'Are you female or male?' },
      choices: {
        sv: ['Kvinna', 'Man', 'Annan / Vill ej svara'],
        us: ['Female', 'Male', 'Other / Do not want to say']
      }
    },
    {
      id: 'age',
      question: { sv: 'Hur gammal är du?', us: 'How old are you?' },
      choices: {
        sv: [
          'Yngre än 18 år',
          '18 - 24 år',
          '25 - 32 år',
          '33 - 40 år',
          '41 - 50 år',
          '51 - 62 år',
          '63 år eller äldre'
        ],
        us: [
          'Younger than 18 years',
          '18 - 24 years',
          '25 - 32 years',
          '33 - 40 years',
          '41 - 50 years',
          '51 - 62 years',
          '63 years or older'
        ]
      }
    },
    {
      id: 'mc',
      question: { sv: 'Vad kör du för typ av motorcykel?', us: 'What kind of motorbike do you ride?' },
      choices: {
        sv: ['Sport', 'Touring', 'Enduro', 'Glidare', 'Naken', 'Trial', 'Offroad', 'Äventyr', 'Annan'],
        us: ['Sport', 'Touring', 'Enduro', 'Custom', 'Naked', 'Trial', 'Offroad', 'Adventure', 'Other']
      },
      other: true
    },
    {
      id: 'time',
      question: { sv: 'Hur länge har du kört motorcykel?', us: 'For how long have you been riding motorbikes?' },
      choices: {
        sv: ['Mindre än 1 år', '1 - 5 år', '6 - 10 år', 'Längre än 10 år'],
        us: ['Less than a year', '1 - 5 years', '6 - 10 years', 'Longer than 10 years']
      }
    },
    {
      id: 'reason',
      question: {
        sv: 'Använder du motorcykeln mest som ett transportmedel eller kör du för nöjes skull?',
        us: 'Do you mostly use your bike as a means of transportation or for fun?'
      },
      choices: {
        sv: ['För transport', 'Nöjeskörning'],
        us: ['For transportation', 'For fun']
      }
    },
    {
      id: 'important',
      question: {
        sv: 'Vad är viktigast för dig när du väljer var du ska köra?',
        us: 'What is most important for you when you decide which route to take?'
      },
      choices: {
        sv: ['Bra vägunderlag', 'Fin utsikt', 'Mycket kurvor', 'Säker väg', 'Kortaste vägen', 'Annat'],
        us: [
          'Good road surface',
          'Nice scenery',
          'A lot of curves',
          'The safety of the road',
          'Shortest way',
          'Something else'
        ]
      },
      other: true
    },
    {
      id: 'accident',
      question: {
        sv: 'Har du någonsin varit med om en mc-olycka?',
        us: 'Have you ever been involved in a motorbike accident?'
      },
      choices: {
        sv: ['Ja, behövde söka vård', 'Ja, sökte ej vård', 'Nej'],
        us: ['Yes, had to go to the hospital', 'Yes, did not go to the hospital', 'No']
      }
    }
  ],
  range: [
    {
      id: 'familiar',
      question: { sv: 'Jag väljer gärna vägar jag kört förut', us: "I prefer to ride routes that I've ridden before" }
    },
    {
      id: 'curves',
      question: {
        sv: 'Jag kör hellre kurviga vägar än raka',
        us: 'I prefer to ride on curvy roads rather than straight ones'
      }
    },
    {
      id: 'friends',
      question: { sv: 'Jag kör helst tillsammans med vänner', us: 'I prefer to ride together with friends' }
    },
    { id: 'gravel', question: { sv: 'Jag älskar att köra grusväg', us: 'I love to ride on gravel roads' } },
    {
      id: 'research',
      question: {
        sv: 'Jag bestämmer var jag ska köra innan jag ger mig ut',
        us: 'I decide where to go before I start riding'
      }
    },
    {
      id: 'discover',
      question: { sv: 'Jag upptäcker gärna nya vägar', us: 'I like to discover new roads' }
    },
    {
      id: 'scenery',
      question: {
        sv: 'Jag tar gärna en omväg för att köra en väg med fin omgivning',
        us: "I don't mind taking a detour to get some nice scenery"
      }
    },
    {
      id: 'highway',
      question: {
        sv: 'Jag kör hellre motorväg än landsväg',
        us: 'I rather drive on the highway than on smaller roads'
      }
    },
    {
      id: 'roadQuality',
      question: {
        sv: 'Vägbanans kvalitet är viktig när jag väljer mina rutter',
        us: 'The quality of the road surface is important when I choose my routes'
      }
    }
  ],
  freeText: [
    {
      id: 'otherFactors',
      question: {
        sv: 'Vilka eventuella övriga faktorer tycker du är viktiga då du väljer rutt att köra?',
        us: 'Are there any other factors you take into consideration when choosing which route to drive?'
      }
    },
    {
      id: 'misc',
      question: {
        sv: 'Har du något övrigt du vill tillägga?',
        us: 'Is there anything else you would like to add?'
      }
    }
  ]
};
