import React from 'react';
import {
  Transition,
  Card,
  Container,
  Statistic,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Segment
} from 'semantic-ui-react';
import theme from './theme';
import axios from 'axios';
import moment from 'moment';
import { ResponsiveContainer, ComposedChart, Bar, XAxis, YAxis, PieChart, Pie, Sector, Cell } from 'recharts';
import questions from './questions';

const INITIAL_STATE = {
  passwordError: true,
  password: '',
  results: null,
  loading: false,
  sent: false
};

class App extends React.Component {
  state = INITIAL_STATE;

  validate = event => {
    event.preventDefault();

    this.setState({ loading: true }, () => {
      axios
        .post(`https://us-central1-detecht-development.cloudfunctions.net/fetchResults`, {
          password: this.state.password
        })
        .then(({ data }) => {
          if (data.error) {
            this.setState(({ passwordError }) => ({ passwordError: !passwordError, loading: false }));
          } else {
            this.setState({ results: data, loading: false });
          }
        })
        .catch(error => console.log(error));
    });
  };

  render() {
    const { results, password, passwordError, loading } = this.state;

    return (
      <Container
        style={{
          backgroundColor: theme.systemBackgroundLight,
          boxShadow: '0px 0px 50px 0px rgba(0,0,0,0.75)',
          padding: 10
        }}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column width={12} />
            <Grid.Column width={4}>
              <Image fluid src="./logo.png" style={{ marginTop: 24, marginBottom: 24 }} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16} textAlign="center">
              <Segment style={{ background: theme.systemBackground, color: theme.white, fontSize: 18 }}>
                {results ? (
                  this.renderResults()
                ) : (
                  <Form onSubmit={this.validate}>
                    <Transition animation="shake" duration={600} visible={passwordError}>
                      <Input
                        size="huge"
                        iconPosition="left"
                        type="password"
                        style={{ marginTop: 80, marginBottom: 80 }}
                        value={password}
                        loading={loading}
                        onChange={input => this.setState({ password: input.target.value })}
                      >
                        <Icon name="key" />
                        <input autoFocus="autofocus" />
                      </Input>
                    </Transition>
                  </Form>
                )}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }

  renderResults() {
    const { results } = this.state;

    const [multichoice, range] = results.reduce(
      (acc, { multichoice, range }) => {
        Object.entries(multichoice).forEach(([key, value]) =>
          Number.isInteger(value) && value >= 0
            ? (acc[0][key][String(value)] += 1)
            : (acc[0][key][String(questions.multichoice.filter(({ id }) => id === key)[0].choices.sv.length - 1)] += 1)
        );
        Object.entries(range).forEach(([key, value]) => (acc[1][key][String(value)] += 1));
        return acc;
      },
      [
        questions.multichoice.reduce(
          (acc, { id, choices: { sv: choices } }) => ({
            ...acc,
            [id]: choices.reduce((acc, next, index) => ({ ...acc, [String(index)]: 0 }), {})
          }),
          {}
        ),
        questions.range.reduce((acc, { id }) => ({ ...acc, [id]: { '1': 0, '2': 0, '3': 0, '4': 0 } }), {})
      ]
    );

    return (
      <div>
        <Header size="huge" style={{ marginTop: 16, marginBottom: 32, color: theme.white }}>
          Results from survey
        </Header>
        <Divider style={{ marginTop: 16 }} inverted />
        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Statistic>
                <Statistic.Value>{results ? results.length : 0}</Statistic.Value>
                <Statistic.Label style={{ color: '#ddd' }}>Responses</Statistic.Label>
              </Statistic>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Statistic>
                <Statistic.Label style={{ color: '#ddd' }}>Last response</Statistic.Label>
                <Statistic.Value text>
                  {moment.unix(results[0].created._seconds).format('D MMM')}
                  <br />
                  <span style={{ fontSize: 32, lineHeight: 1.2 }}>
                    {moment.unix(results[0].created._seconds).format('HH:mm')}
                  </span>
                </Statistic.Value>
              </Statistic>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider inverted />
        <Grid columns="equal" stackable>
          {[
            ['sex', 'accident', 'reason'],
            ['age', 'time'],
            ['important', 'mc']
          ].map((options, index) => (
            <Grid.Row key={`multichoice-row-${index}`}>
              {options.map(question => (
                <Grid.Column textAlign="center" key={`multichoice-${question}`}>
                  <Header.Subheader>
                    {questions.multichoice.find(({ id }) => id === question).question.us}
                  </Header.Subheader>
                  <div
                    style={{
                      width: '100%',
                      height: questions.multichoice.find(({ id }) => id === question).choices.us.length > 3 ? 440 : 240
                    }}
                  >
                    <ResponsiveContainer>
                      <ComposedChart
                        layout="vertical"
                        width={500}
                        height={440}
                        data={questions.multichoice
                          .find(({ id }) => id === question)
                          .choices.us.map((option, index) => {
                            if (option === 'Other / Do not want to say') {
                              option = 'Other';
                            } else if (option === 'Younger than 18 years') {
                              option = '< 18 years';
                            } else if (option === '63 years or older') {
                              option = '> 62 years';
                            } else if (option === 'For transportation') {
                              option = 'Transportation';
                            } else if (option === 'Yes, had to go to the hospital') {
                              option = 'Yes, hospital';
                            } else if (option === 'Yes, did not go to the hospital') {
                              option = 'Yes, no hospital';
                            } else if (option === 'Less than a year') {
                              option = '< 1 year';
                            } else if (option === 'Longer than 10 years') {
                              option = '> 10 years';
                            }

                            return {
                              option,
                              response: multichoice[question][String(index)]
                            };
                          })}
                        margin={{
                          top: 20,
                          right: 16,
                          bottom: 20,
                          left: 62
                        }}
                      >
                        <XAxis type="number" />
                        <YAxis type="category" dataKey="option" padding={{ top: 30, bottom: 30 }} />
                        <Bar dataKey="response" barSize={32} fill={theme.orange} barGap="0%" />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                </Grid.Column>
              ))}
            </Grid.Row>
          ))}
        </Grid>
        <Divider inverted />
        <Grid columns={3} stackable>
          <Grid.Row>
            {questions.range.map(({ id, question }, index) => (
              <Grid.Column key={`range-${index}`}>
                <Header.Subheader>{question.us}</Header.Subheader>
                <div style={{ width: '100%', height: 200 }}>
                  <ResponsiveContainer>
                    <ComposedChart
                      width={500}
                      height={400}
                      data={[
                        {
                          name: '1',
                          rating: range[id]['1']
                        },
                        {
                          name: '2',
                          rating: range[id]['2']
                        },
                        {
                          name: '3',
                          rating: range[id]['3']
                        },
                        {
                          name: '4',
                          rating: range[id]['4']
                        }
                      ]}
                      margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20
                      }}
                    >
                      <XAxis dataKey="name" padding={{ left: 32, right: 32 }} />
                      <YAxis />
                      <Bar dataKey="rating" barSize={40} fill={theme.orange} barGap="0%" />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
        <Divider inverted />

        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column>
              <Header.Subheader color={theme.white}>
                {questions.freeText.filter(({ id }) => id === 'misc')[0].question['us']}
              </Header.Subheader>
              {results
                .filter(({ freeText }) => !!freeText)
                .filter(
                  ({
                    freeText: {
                      misc: { length }
                    }
                  }) => length > 4
                )
                .map(({ freeText: { misc } }, index) => (
                  <Card key={`misc-${index}`} fluid>
                    <Card.Content style={{ color: theme.systemBackground }}>{misc.trim()}</Card.Content>
                  </Card>
                ))}
            </Grid.Column>
            <Grid.Column>
              <Header.Subheader color={theme.white}>
                {questions.freeText.filter(({ id }) => id === 'otherFactors')[0].question['us']}
              </Header.Subheader>
              {results
                .filter(({ freeText }) => !!freeText)
                .filter(
                  ({
                    freeText: {
                      otherFactors: { length }
                    }
                  }) => length > 4
                )
                .map(({ freeText: { otherFactors } }, index) => (
                  <Card fluid key={`otherFactors-${index}`}>
                    <Card.Content style={{ color: theme.systemBackground }}>{otherFactors.trim()}</Card.Content>
                  </Card>
                ))}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {/* {JSON.stringify(results)} */}
      </div>
    );
  }
}

export default App;
